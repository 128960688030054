@import "../../../Variables";

.dateInput {
  &:hover {
    text-decoration: underline;
  }
}

.container {
  max-width: 1300px;
  padding: 0 16px;
}

.listings {
  max-width: 100%;

  .table {
    table-layout: fixed;

    .tableRow {
      th {
        background: $colorLightGray !important;
      }

      border-bottom: 2px solid $borderColor;

      .avatar {
        height: 50px;
        width: 50px;
        border-radius: unset;
      }
    }
  }
}

.formTable {
  margin-bottom: 3rem !important;
  padding-bottom: 3rem;
}

.tableFooter {
  bottom: 0.75rem;
  margin-bottom: 0;
  //border-top: 2px solid rgba(51, 51, 51, 0.07);
}

@media only screen and (max-width: 1024px) {
  .listings {
    .table {
      table-layout: unset;
    }
  }
}

@media only screen and (max-width: 425px) {
  .listings {
    .table {
      .tableRow {
        .avatar {
          height: 40px;
          width: 40px;
        }
      }
    }
  }
}