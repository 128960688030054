.label {
    color: #001e3c;
}
.value {
    color: #001e3c;
    font-weight: 700;

    a {
        color: #001e3c;

        &:hover {
            text-decoration: underline;
        }
    }
}
