@import '../../../Variables';

.inputContainer {
    .inputLabels {
        display: flex !important;
        font-size: 1.2rem !important;
        color: $colorPrimary;
        align-items: center;
    }

    .normalMarginAndPadding {
        padding: 18.5px 14px;
        margin-top: unset;
        cursor: pointer;
    }

    .datePicker {
        //border: 1px solid rgba(0, 0, 0, 0.23) !important;
        padding: 12px;
        -webkit-border-radius: 4px !important;
        -moz-border-radius: 4px !important;
        border-radius: 4px !important;

        &:hover {
            //border: 1px solid rgba(0, 0, 0, 0.87) !important;
            cursor: pointer;
        }

        div {
            &:before {
                border-bottom: none !important;
            }

            &:after {
                border: none !important;
            }
        }
    }

    .inputContainer {
        input {
            padding: 12px;
        }
    }

    .inputFields {
        width: 100%;
        background-color: white;
        margin-top: 1rem;
    }
}
