.imageListContainer {
  display: flex;
  align-items: center;
  margin: 1rem;
  width: 100%;
  flex-wrap: wrap;

  .imageListGrid {
    text-align: center;
    margin: 1rem 0;
  }

  .imageListItem {
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 10px;
  }
}

.imageListGrid {
  position: relative;

  &:hover {
    .clearIcon {
      display: block;
    }
  }

  .clearIcon {
    cursor: pointer;
    padding: 3px;
    height: 30px;
    width: 30px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    display: none;
    color: white;
    background-color: #3f50b5;
    position: absolute;
    right: 1rem;
    top: -5px;
    font-size: 30px;
  }

  .imageName {
    font-size: 15px;
    max-width: 130px;
    margin: auto;
  }
}
