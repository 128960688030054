@import 'Variables.scss';

html,
body {
    margin: 0;
    padding: 0;
    //height: 100%;
    overflow-y: scroll !important;
    //overscroll-behavior-y: none;
    color: $colorPrimary;
    scroll-behavior: smooth;

    ::-webkit-scrollbar {
        width: 7px;
    }

    ::-webkit-scrollbar-thumb {
        background: $colorPrimary;
        border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
        background: $colorWhite;
    }
}

body {
    #__next {
        //height: 100%
    }

    margin: 0;
    padding: 0 !important;
    height: 100%;
    overflow: unset !important;
    background-color: $colorWhite !important;

    .itemLink:link {
        color: $colorPrimary;
        text-decoration: none;
    }

    .logo {
        font-size: 30px;
        font-weight: bold;

        &:after {
            display: block;
            text-align: center;
            font-size: 50px;
            padding: 10px 20px;
            color: rgb(0, 255, 255);
            mix-blend-mode: difference;
        }
    }
}

input,
textarea {
    max-width: 100%;
}

.mainLoader {
    height: 100vh;
    padding: 1px;
    display: flex;
    align-items: center;
}

.secondaryTwo {
    background-color: #59c3ff !important;
    color: white !important;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

button {
    .circular-progress {
        margin: 0 0 0 0.7rem;
        color: $colorWhite;
    }

    .circular-progress-primary {
        margin: 0 0 0 0.7rem;
        color: $colorBlue;
    }
}

.loader {
    border-radius: 50%;
    width: 10em;
    height: 10em;
    font-size: 4px;
    margin: 60px auto;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid #f3ebeb;
    border-bottom: 1.1em solid #f3ebeb;
    border-left: 1.1em solid #333333;
    border-right: 1.1em solid #f3ebeb;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 0.5s infinite linear;
    -moz-animation: load8 0.5s infinite linear;
    animation: load8 0.5s infinite linear;

    &:after {
        border-radius: 50%;
        width: 10em;
        height: 10em;
    }
}
.loader-button {
    border: 2px solid #f3f3f3;
    border-top: 2px solid black;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-top: 2px;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.max-w-500 {
    max-width: 500px !important;
}
.max-w-700 {
    max-width: 700px !important;
}
/*.loader {
  border-radius: 50%;
  width: 10em;
  height: 10em;
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid rgb(51, 51, 51);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;

  &:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
}*/
.scrollTopChatPopupOffset {
    right: 24rem !important;
}

.scrollTopHome {
    bottom: 1rem !important;
}

.scrollTop {
    display: none;
    position: fixed !important;
    bottom: 4rem;
    right: 0.5rem;
    z-index: 1111;
}

.contactUs {
    position: fixed !important;
    bottom: 0;
    left: 1rem;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

$max: 100;
$maxMargin: 50;
$maxPadding: 50;

@for $i from 1 through $max {
    .fs-#{$i} {
        font-size: #{$i}px !important;
    }
}

@for $i from 0 through $max {
    .line-height-#{$i} {
        line-height: #{$i}px !important;
    }
}

.fs-unset {
    font-size: unset !important;
}

.m-unset {
    margin: unset !important;
}

@for $i from 0 through $maxMargin {
    .m-#{$i} {
        margin: #{$i * 0.25}rem !important;
    }
    .mx-#{$i} {
        margin-left: #{$i * 0.25}rem !important;
        margin-right: #{$i * 0.25}rem !important;
    }
    .my-#{$i} {
        margin-top: #{$i * 0.25}rem !important;
        margin-bottom: #{$i * 0.25}rem !important;
    }
    .mt-#{$i} {
        margin-top: #{$i * 0.25}rem !important;
    }
    .mb-#{$i} {
        margin-bottom: #{$i * 0.25}rem !important;
    }
    .ml-#{$i} {
        margin-left: #{$i * 0.25}rem !important;
    }
    .mr-#{$i} {
        margin-right: #{$i * 0.25}rem !important;
    }
}

@for $i from 0 through $maxPadding {
    .p-#{$i} {
        padding: #{$i * 0.25}rem !important;
    }
    .px-#{$i} {
        padding-left: #{$i * 0.25}rem !important;
        padding-right: #{$i * 0.25}rem !important;
    }
    .py-#{$i} {
        padding-top: #{$i * 0.25}rem !important;
        padding-bottom: #{$i * 0.25}rem !important;
    }
    .pt-#{$i} {
        padding-top: #{$i * 0.25}rem !important;
    }
    .pb-#{$i} {
        padding-bottom: #{$i * 0.25}rem !important;
    }
    .pl-#{$i} {
        padding-left: #{$i * 0.25}rem !important;
    }
    .pr-#{$i} {
        padding-right: #{$i * 0.25}rem !important;
    }
}

.pointer-events-none {
    pointer-events: none;
}

.object-fit-scale-down {
    object-fit: scale-down !important;
}

.flex-no-wrap {
    flex-wrap: nowrap !important;
}

.line-height-unset {
    line-height: unset !important;
}

.text-underline {
    text-decoration: underline;
}

.text-hover-underline {
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.text-hover-colorWhite {
    &:hover {
        color: $colorWhite;
    }
}

.text-hover-colorPurple {
    &:hover {
        color: $colorPurple !important;
    }
}

.text-hover-bgColorPurple {
    &:hover {
        background-color: $colorPurple !important;
    }
}

.text-hover-colorWhite {
    &:hover {
        color: $colorWhite !important;
    }
}

.z-index-1000 {
    z-index: 1000 !important;
}

.pageTitle {
    padding: 0.2rem 0 0.2rem 0;
    background: $colorLightGray;
    border-radius: 4px;
    width: 150px;
}

.button-hover-blue {
    background-color: rgba(99, 91, 255, 0.04) !important;
}

.object-fit-contain {
    object-fit: contain;
}

.object-fit-cover {
    object-fit: cover;
}

.line-clamp-unset {
    -webkit-line-clamp: unset !important;
}

.line-clamp-1 {
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.line-clamp-5 {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    p {
        margin-top: 0;
    }
}

.d-none {
    display: none !important;
}

.m-auto {
    margin: auto !important;
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.w-25 {
    width: 25% !important;
}

.w-40 {
    width: 40% !important;
}

.w-50 {
    width: 50% !important;
}

.w-70 {
    width: 70% !important;
}

.w-75 {
    width: 75% !important;
}

.w-80 {
    width: 80% !important;
}

.w-85 {
    width: 85% !important;
}

.w-90 {
    width: 90% !important;
}

.w-100 {
    width: 100% !important;
}

.w-unset {
    width: unset !important;
}

.w-max-content {
    width: max-content !important;
}

.h-0 {
    height: 0 !important;
}

.h-50 {
    height: 50%;
}

.h-100 {
    height: 100%;
}

.vh-100 {
    height: 100vh;
}

.cursor-pointer {
    cursor: pointer !important;
}

.position-unset {
    position: unset !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.position-fixed {
    position: fixed;
}

.colorDisabled {
    color: $colorDisabled !important;
}

.bgHeaderColor {
    background-color: $headerBg !important;
}

.bgColorDisabled {
    background-color: $colorDisabled !important;
}

.colorPrimary {
    color: $colorPrimary !important;
}

.colorGrayText {
    color: #52667b;
}

.colorGrayLightText {
    color: #babfc2;
}

.colorUnset {
    color: unset !important;
}

.bgColorUnset {
    background-color: unset !important;
}

.colorSecondary {
    color: $colorSecondary !important;
}

.colorBlue {
    color: $colorBlue !important;
}

.colorGreen {
    color: $colorGreen !important;
}

.colorGray {
    color: $colorGray !important;
}

.colorLighterGray {
    color: $colorLighterGray !important;
}

.colorLightGray {
    color: $colorLightGray !important;
}

.bgColorLightGray {
    background: $colorLightGray !important;
}

.colorChipsBlue {
    color: $chipsBlue !important;
}

.bgChipsBlue {
    background: $chipsBlue !important;
}

.bgColorPrimary {
    background-color: $colorPrimary !important;
}

.colorWhite {
    color: $colorWhite !important;
}

.colorPurple {
    color: $colorPurple !important;
}

.bgColorPurple {
    background-color: $colorPurple !important;
}

.bgColorWhite {
    background: $colorWhite !important;
}

.bgColorBlue {
    background: $colorBlue !important;
}

.bgColorRed {
    background: $colorDanger !important;
}

.bgColorGreen {
    background: $colorGreen !important;
}

.colorDanger {
    color: $colorDanger !important;
}

.colorSecondary {
    color: $colorSecondary !important;
}

.colorDisabled {
    color: #dddddd !important;
}

.text-justify {
    text-align: justify !important;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.d-unset {
    display: unset !important;
}

.d-grid {
    display: grid !important;
}

.d-block {
    display: block !important;
}

.d-inline-block {
    display: inline-block !important;
}

.top-05 {
    top: 0.5rem !important;
}

.top-0 {
    top: 0 !important;
}

.left-0 {
    left: 0 !important;
}

.right-0 {
    right: 0 !important;
}

.border-0 {
    border: none !important;
}

.border-radius-4 {
    border-radius: 4px;
}

.border-radius-50 {
    border-radius: 50px !important;
}

.border-radius-unset {
    border-radius: unset !important;
}

.border-radius-12 {
    border-radius: 12px;
}

.border-b-radius-unset {
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
}

.border {
    border: 1px solid #e1e1e1 !important;
}

.border-dashed {
    border: 1px dashed rgba(51, 51, 51, 0.07) !important;
}

.border-white {
    border: 1px solid $colorWhite !important;
}

.border-left {
    border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.border-right {
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.border-top {
    border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.border-bottom-5px {
    border-bottom: 5px solid rgba(0, 0, 0, 0.12) !important;
}

.header-border-bottom {
    border-bottom: 2px solid rgba(51, 51, 51, 0.07);
}

.inset-0 {
    inset: 0;
}

.text-inherit {
    text-transform: inherit !important;
}

.bg-grey-header {
    background: #f7f7f7;
}

.multiItemsCardBg {
    background: #f9f9f9;
}

.text-decoration-none {
    text-decoration: none;
}

.font-public-sans {
    font-family: $publicSans;
}

.font-circular-std {
    font-family: $circularStd;
}

.font-circular-std-medium {
    font-family: $circularStdMedium;
}

.font-weight-bold {
    font-weight: bold !important;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.font-weight-normal {
    font-weight: normal !important;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

.word-break-word {
    word-break: break-word;
}

.d-flex {
    display: flex !important;
}

.w-0 {
    width: 0;
}

.w-unset {
    width: unset !important;
}

.w-60 {
    width: 60% !important;
}

.h-unset {
    height: unset !important;
}

.min-h-unset {
    min-height: unset !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.row-gap-10 {
    row-gap: 10px !important;
}

.grid-column-gap-05 {
    grid-column-gap: 0.5rem !important;
}

.grid-column-gap-2 {
    grid-column-gap: 2rem !important;
}

.align-self-center {
    align-self: center !important;
}

.align-self-flex-end {
    align-self: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-unset {
    align-items: unset !important;
}

.align-items-flex-start {
    align-items: flex-start !important;
}

.align-items-flex-end {
    align-items: flex-end !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.flex-direction-column {
    flex-direction: column !important;
}

.flex-direction-row-reverse {
    flex-direction: row-reverse !important;
}

.flex-grow-1 {
    flex-grow: 1;
}
.flex-grow-05 {
    flex-grow: 0.5;
}

.flex-grow-2 {
    flex-grow: 2;
}

.flex-wrap-unset {
    flex-wrap: unset;
}

.list-style-type-none {
    list-style-type: none;
}

.detailsPageContainer {
    padding-bottom: 6rem;
    max-width: 1300px !important;
    margin: auto;
}

.profileActionRequiredBadge {
    top: 10% !important;
    //top: 3% !important;
    //top: 40% !important;
    //right: 30px !important;
    //animation: alertPulsate 1s ease-out;
    //animation-iteration-count: infinite;
    opacity: 1;
}

.blur {
    filter: blur(7px);
}

.disableText {
    color: rgba(0, 0, 0, 0.26);
}

.actionRequiredBadge {
    height: 8px;
    padding: 0;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgb(220, 0, 78);
    //animation: alertPulsate 1s ease-out;
    //animation-iteration-count: infinite;
    opacity: 1;
}

.btn-subscribe-style {
    background-color: $colorGreen !important;
    color: $colorWhite !important;
}

.btn-submit-style {
    background-color: $colorGreen !important;
    padding: 6px 70px !important;
    color: $colorWhite !important;
}

.btn-submit-post {
    // position: fixed;
    bottom: 0;
    background: $colorWhite;
    left: 0;
    margin: auto;
    right: 0;
}

@-webkit-keyframes alertPulse {
    /* 0% {
   background-color: #9A2727;
   opacity: 1;
 }
 50% {
   opacity: red;
   opacity: 0.75;
 }
 100% {
   opacity: #9A2727;
   opacity: 1;
 }*/
}

@-webkit-keyframes alertPulsate {
    0% {
        -webkit-transform: scale(0.1, 0.1);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

.appCard {
    box-shadow: $listingsCardShadow !important;
    border: $border;
    border-radius: 4px;
}

.bottom-shadow {
    box-shadow: 0 2px 6px -2px rgb(0 106 194 / 20%) !important;
}

.bottom-shadow-common {
    box-shadow: 0px 0px 1px #adadff, 0px 2px 8px rgba(0, 0, 100, 0.06) !important;
}

.lightGrayButton {
    background-color: $colorLightGray !important;

    &:hover {
        border-bottom: unset;
    }
}

.box-shadow-none {
    box-shadow: none !important;
}

.appLogo {
    /*img {
max-width: 100%;
height: auto;
display: block;
}*/

    //margin-right: 1rem;

    .favicon {
        width: 35px;
        height: 35px;
    }

    .logo {
        width: 150px;
        //height: 23px;
    }
}

$pageMarginTop: 1rem;
.appContainer {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .appContent {
        //top: 102px;
        //height: calc(100vh - 102px);
        //margin-top: $appHeaderHeight;
        //position: fixed;
        width: 100%;
        //overflow: auto;

        .listingContainer {
            width: 100%;
            max-width: 1000px;
            padding-top: 1rem;
            padding-bottom: 6rem;
        }

        .offersListingContainer {
            width: 100%;
            max-width: 1000px;
            padding-top: 1rem;
            padding-bottom: 6rem;
        }
    }

    .pageContainer {
        margin: $pageMarginTop 0 0;
    }

    .appBottomDrawer {
        display: none;
    }

    .appMenu {
        //position: fixed;
        //top: $appHeaderHeight;
        z-index: 10000;
        background: white;
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        height: 30px;
        //-webkit-box-shadow: 0 2px 4px -1px rgb(0 0 0 / 7%), 0 4px 5px 0 rgb(0 0 0 / 0%), 0 1px 10px 0 rgb(0 0 0 / 10%);
        //-moz-box-shadow: 0 2px 4px -1px rgb(0 0 0 / 7%), 0 4px 5px 0 rgb(0 0 0 / 0%), 0 1px 10px 0 rgb(0 0 0 / 10%);
        //border-bottom: 2px solid rgba(51, 51, 51, .07);
        box-shadow: 0 2px 6px -2px rgb(0 106 194 / 20%);
        border-bottom: solid 1px #ebf0f6;
        margin-top: 0.5rem;

        .headerSearchIcon {
            top: -4px;
            right: -2rem;

            &:hover {
                background-color: $colorWhite;
            }
        }
    }

    .appHeader {
        padding-right: 0 !important;
        color: $colorPrimary;
        background-color: $colorWhite;
        //-webkit-box-shadow: 0 2px 4px -1px rgb(0 0 0 / 7%), 0 4px 5px 0 rgb(0 0 0 / 0%), 0 1px 10px 0 rgb(0 0 0 / 10%);
        //-moz-box-shadow: 0 2px 4px -1px rgb(0 0 0 / 7%), 0 4px 5px 0 rgb(0 0 0 / 0%), 0 1px 10px 0 rgb(0 0 0 / 10%);
        //border-bottom: 2px solid rgba(51, 51, 51, .07);
        //box-shadow: unset;
        //box-shadow: 0 2px 4px -1px rgb(0 0 0 / 7%), 0 4px 5px 0 rgb(0 0 0 / 0%), 0 1px 10px 0 rgb(0 0 0 / 10%);
        //z-index: 11111;

        .appToolbar {
            //max-height: $appHeaderHeight;
            min-height: unset;
            width: 100%;
            //max-width: 1300px;
            //max-width: 1150px;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            /*padding-left: 16px;
padding-right: 16px;*/

            .currentPageTitle {
                flex-grow: 1;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 16px !important;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            .actionArea {
                .avatar {
                    border: 2px solid rgba(51, 51, 51, 0.07);
                    border-radius: 50%;
                    margin: auto;
                    cursor: pointer;
                }

                .submitIcon {
                    margin-right: 10px;
                }

                .btnAccount {
                    font-size: 16px;
                    text-decoration: none;
                    font-weight: bold;
                    text-transform: uppercase;
                }
            }
        }

        .actionArea {
            .btnLogin {
                margin-left: 10px;
                margin-right: 10px;
                font-weight: 600;
            }

            .authButtonsGroup {
                display: flex;
                align-items: center;
            }

            .authButtonsGroupMobile {
                display: none;
            }
        }

        .icon {
            margin-right: 5px;
        }
    }
}

footer {
    //position: fixed;
    //bottom: 0;
    width: 100%;
}

@media only screen and (max-width: 1280px) {
    @for $i from 1 through $max {
        .fs-md-#{$i} {
            font-size: #{$i}px !important;
        }
    }

    @for $i from 0 through $maxPadding {
        .p-md-#{$i} {
            padding: #{$i * 0.25}rem !important;
        }
        .px-md-#{$i} {
            padding-left: #{$i * 0.25}rem !important;
            padding-right: #{$i * 0.25}rem !important;
        }
        .py-md-#{$i} {
            padding-top: #{$i * 0.25}rem !important;
            padding-bottom: #{$i * 0.25}rem !important;
        }
        .pt-md-#{$i} {
            padding-top: #{$i * 0.25}rem !important;
        }
        .pb-md-#{$i} {
            padding-bottom: #{$i * 0.25}rem !important;
        }
        .pl-md-#{$i} {
            padding-left: #{$i * 0.25}rem !important;
        }
        .pr-md-#{$i} {
            padding-right: #{$i * 0.25}rem !important;
        }
    }

    @for $i from 0 through $maxMargin {
        .m-md-#{$i} {
            margin: #{$i * 0.25}rem !important;
        }
        .mx-md-#{$i} {
            margin-left: #{$i * 0.25}rem !important;
            margin-right: #{$i * 0.25}rem !important;
        }
        .my-md-#{$i} {
            margin-top: #{$i * 0.25}rem !important;
            margin-bottom: #{$i * 0.25}rem !important;
        }
        .mt-md-#{$i} {
            margin-top: #{$i * 0.25}rem !important;
        }
        .mb-md-#{$i} {
            margin-bottom: #{$i * 0.25}rem !important;
        }
        .ml-md-#{$i} {
            margin-left: #{$i * 0.25}rem !important;
        }
        .mr-md-#{$i} {
            margin-right: #{$i * 0.25}rem !important;
        }
    }

    .d-md-flex {
        display: flex !important;
    }
    .d-md-none {
        display: none !important;
    }
    .d-md-block {
        display: block !important;
    }

    .w-md-50 {
        width: 50% !important;
    }

    .w-md-60 {
        width: 60% !important;
    }

    .w-md-70 {
        width: 70% !important;
    }

    .w-md-75 {
        width: 75% !important;
    }

    .w-md-80 {
        width: 80% !important;
    }

    .w-md-90 {
        width: 90% !important;
    }

    .w-md-100 {
        width: 100% !important;
    }

    .flex-direction-md-column {
        flex-direction: column !important;
    }
    .justify-content-md-center {
        justify-content: center !important;
    }
    .justify-content-md-start {
        justify-content: flex-start !important;
    }

    .appContainer {
        .appHeader {
            .appToolbar {
                min-height: unset;
                width: 100%;
                padding-left: 10px;
                padding-right: 16px;
            }
        }

        .appHeader {
            .actionArea {
                .btnSubmitAd {
                    .submitIcon {
                        margin-right: 0;
                    }

                    /*.label {
  display: none;
}*/
                }
            }
        }
    }
}

@media only screen and (max-width: 960px) {
    .line-clamp-sm-1 {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .m-sm-auto {
        margin: auto !important;
    }
    .contactUs {
        //bottom: 55px !important;
    }

    .scrollTop {
        bottom: 8rem;
        right: 1.3rem;
    }

    @for $i from 1 through $max {
        .fs-sm-#{$i} {
            font-size: #{$i}px !important;
        }
    }

    .position-sm-unset {
        position: unset !important;
    }

    @for $i from 0 through $maxPadding {
        .p-sm-#{$i} {
            padding: #{$i * 0.25}rem !important;
        }
        .px-sm-#{$i} {
            padding-left: #{$i * 0.25}rem !important;
            padding-right: #{$i * 0.25}rem !important;
        }
        .py-sm-#{$i} {
            padding-top: #{$i * 0.25}rem !important;
            padding-bottom: #{$i * 0.25}rem !important;
        }
        .pt-sm-#{$i} {
            padding-top: #{$i * 0.25}rem !important;
        }
        .pb-sm-#{$i} {
            padding-bottom: #{$i * 0.25}rem !important;
        }
        .pl-sm-#{$i} {
            padding-left: #{$i * 0.25}rem !important;
        }
        .pr-sm-#{$i} {
            padding-right: #{$i * 0.25}rem !important;
        }
    }

    @for $i from 0 through $maxMargin {
        .m-sm-#{$i} {
            margin: #{$i * 0.25}rem !important;
        }
        .mx-sm-#{$i} {
            margin-left: #{$i * 0.25}rem !important;
            margin-right: #{$i * 0.25}rem !important;
        }
        .my-sm-#{$i} {
            margin-top: #{$i * 0.25}rem !important;
            margin-bottom: #{$i * 0.25}rem !important;
        }
        .mt-sm-#{$i} {
            margin-top: #{$i * 0.25}rem !important;
        }
        .mb-sm-#{$i} {
            margin-bottom: #{$i * 0.25}rem !important;
        }
        .ml-sm-#{$i} {
            margin-left: #{$i * 0.25}rem !important;
        }
        .mr-sm-#{$i} {
            margin-right: #{$i * 0.25}rem !important;
        }
    }
    .align-items-md-unset {
        align-items: unset !important;
    }
    .align-items-md-start {
        align-items: flex-start !important;
    }
    .appContainer {
        .appContent {
            .listingContainer {
                width: 100%;
                max-width: 680px;
            }
        }
    }

    .flex-direction-column-sm-reverse {
        flex-direction: column-reverse !important;
    }

    .justify-content-sm-unset {
        justify-content: unset !important;
    }

    .justify-content-sm-center {
        justify-content: center !important;
    }

    .flex-direction-sm-column {
        flex-direction: column;
    }

    .flex-direction-sm-row {
        flex-direction: row !important;
    }

    .align-items-sm-unset {
        align-items: unset !important;
    }

    .text-sm-center {
        text-align: center !important;
    }

    .d-sm-unset {
        display: unset !important;
    }

    .d-sm-inline-block {
        display: inline-block !important;
    }

    .d-sm-block {
        display: block !important;
    }
    .d-sm-flex {
        display: flex !important;
    }
    .d-sm-none {
        display: none !important;
    }
    .flex-direction-sm-reverse {
        flex-direction: row-reverse;
    }

    .w-sm-50 {
        width: 50% !important;
    }

    .w-sm-60 {
        width: 60% !important;
    }

    .w-sm-70 {
        width: 70% !important;
    }

    .w-sm-75 {
        width: 75% !important;
    }

    .w-sm-80 {
        width: 80% !important;
    }

    .w-sm-90 {
        width: 90% !important;
    }

    .w-sm-100 {
        width: 100% !important;
    }

    .w-sm-unset {
        width: unset !important;
    }

    .d-flex-sm-unset {
        display: unset !important;
    }

    .align-items-sm-center {
        align-items: center !important;
    }

    .align-items-sm-flex-start {
        align-items: flex-start !important;
    }

    .align-items-sm-flex-end {
        align-items: flex-end !important;
    }
    .flex-direction-sm-column-reverse {
        flex-direction: column-reverse;
    }

    .appContainer {
        //margin-bottom: 60px;

        .appBottomDrawer {
            display: block;
        }

        .appHeader {
            .appToolbar {
                min-height: unset;

                .currentPageTitle {
                    flex-grow: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 14px !important;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .logo {
        /*border: 1px solid #F2F3F5;
padding: 5px;
display: inline;
text-transform: uppercase;
background-color: #F2F3F5;*/

        &:after {
            display: block;
            text-align: center;
            font-size: 50px;
            padding: 10px 20px;
            color: rgb(0, 255, 255);
            mix-blend-mode: difference;
        }
    }
    .CookieConsent {
        div {
            width: 100%;
        }
    }

    .flex-direction-xs-unset {
        flex-direction: unset;
    }

    .xs-visible {
        visibility: visible !important;
    }

    .xs-invisible {
        visibility: hidden !important;
    }

    .font-weight-xs-bold {
        font-weight: bold !important;
    }

    .font-weight-xs-normal {
        font-weight: normal !important;
    }

    .w-xs-100 {
        width: 100% !important;
    }

    .w-xs-90 {
        width: 90% !important;
    }

    .w-xs-unset {
        width: unset !important;
    }

    .h-xs-unset {
        height: unset !important;
    }

    .position-xs-unset {
        position: unset !important;
    }

    @for $i from 1 through $max {
        .fs-xs-#{$i} {
            font-size: #{$i}px !important;
        }
    }

    @for $i from 0 through $maxPadding {
        .p-xs-#{$i} {
            padding: #{$i * 0.25}rem !important;
        }
        .px-xs-#{$i} {
            padding-left: #{$i * 0.25}rem !important;
            padding-right: #{$i * 0.25}rem !important;
        }
        .py-xs-#{$i} {
            padding-top: #{$i * 0.25}rem !important;
            padding-bottom: #{$i * 0.25}rem !important;
        }
        .pt-xs-#{$i} {
            padding-top: #{$i * 0.25}rem !important;
        }
        .pb-xs-#{$i} {
            padding-bottom: #{$i * 0.25}rem !important;
        }
        .pl-xs-#{$i} {
            padding-left: #{$i * 0.25}rem !important;
        }
        .pr-xs-#{$i} {
            padding-right: #{$i * 0.25}rem !important;
        }
    }

    @for $i from 0 through $maxMargin {
        .m-xs-#{$i} {
            margin: #{$i * 0.25}rem !important;
        }
        .mx-xs-#{$i} {
            margin-left: #{$i * 0.25}rem !important;
            margin-right: #{$i * 0.25}rem !important;
        }
        .my-xs-#{$i} {
            margin-top: #{$i * 0.25}rem !important;
            margin-bottom: #{$i * 0.25}rem !important;
        }
        .mt-xs-#{$i} {
            margin-top: #{$i * 0.25}rem !important;
        }
        .mb-xs-#{$i} {
            margin-bottom: #{$i * 0.25}rem !important;
        }
        .ml-xs-#{$i} {
            margin-left: #{$i * 0.25}rem !important;
        }
        .mr-xs-#{$i} {
            margin-right: #{$i * 0.25}rem !important;
        }
    }

    .align-items-xs-unset {
        align-items: unset !important;
    }

    .align-items-xs-flex-start {
        align-items: flex-start !important;
    }

    .align-self-flex-xs-end {
        align-self: flex-end !important;
    }

    .d-xs-none {
        display: none !important;
    }
    .d-xs-flex {
        display: flex !important;
    }
    .text-xs-right {
        text-align: right !important;
    }
    .text-xs-left {
        text-align: left !important;
    }
    .d-xs-block {
        display: block !important;
    }
    .d-xs-inline {
        display: inline !important;
    }
    .text-xs-center {
        text-align: center !important;
    }
    .m-xs-auto {
        margin: auto !important;
    }
    .align-items-xs-center {
        align-items: center !important;
    }

    .flex-direction-xs-column {
        flex-direction: column;
    }
    .flex-direction-xs-column-reverse {
        flex-direction: column-reverse;
    }

    .justify-content-xs-end {
        justify-content: end !important;
    }

    .justify-content-xs-center {
        justify-content: center !important;
    }

    .justify-content-xs-left {
        justify-content: left !important;
    }

    .justify-content-xs-between {
        justify-content: space-between !important;
    }

    .flex-direction-xs-column {
        flex-direction: column !important;
    }

    .appContainer {
        .appContent {
            .listingContainer {
                width: 100%;
                max-width: 410px;
            }
        }

        .appBottomDrawer {
            display: block;
        }

        .appHeader {
            .appToolbar {
                min-height: unset;

                .currentPageTitle {
                    font-size: 12px !important;
                }
            }

            .actionArea {
                .btnLogin {
                    margin: 0;
                }
            }

            .actionArea {
                .authButtonsGroupMobile {
                    display: block;
                }
            }
        }
    }
}

.MuiFormLabel-root.Mui-error {
    font-size: 0.75rem;
    margin-left: 14px;
    margin-top: 5px;
}

.infinite-scroll-component__outerdiv {
    width: 100%;
}

.emoji-mart {
    width: 98% !important;
}

.quill {
    .ql-container {
        font-size: 16px;
        min-height: 200px;
        max-height: 500px;
        overflow: auto;

        .ql-tooltip {
            left: 15px;
        }

        .ql-blank,
        .ql-editor {
            min-height: 200px;
            max-height: 500px;

            &:before {
                font-size: 16px;
                font-style: normal;
            }
        }
    }
}

.opacity50 {
    opacity: 0.5;
}

.opacity60 {
    opacity: 0.6;
}

.opacity-0 {
    opacity: 0;
}

.opacity-1 {
    opacity: 1;
}

.brightness-0 {
    filter: brightness(0);
}

.phoneInput {
    padding: 21px 0 21px 27px;
    width: 100% !important;
}
.public-DraftStyleDefault-block {
    margin: 0;
    padding: 0;
}
.DraftEditor-editorContainer {
    margin-top: 1rem;
}
