.searchBar {
  border-radius: 20px;
  cursor: text;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.13), 0 0 3px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
  padding: 0 20px;
  width: 100%;
  min-height: 40px;
  font-size: 14px;
  border: none;
  outline: transparent;

  &:hover {
    background-color: rgb(255, 255, 255);
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    box-shadow: rgba(0, 0, 0, 0.13) 0 7px 15px, rgba(0, 0, 0, 0.05) 0 0 3px;
  }
}