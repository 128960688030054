@import './../../../Variables';

.inputContainer {
    .inputLabels {
        display: unset !important;
        font-size: 0.875rem !important;
        color: $colorPrimary !important;
    }

    input {
        height: 1.4375em;
        padding: 10px 14px;
    }

    .disabled {
        background: $inputDisabledBg;
        color: $colorPrimary;
    }

    .inputFields {
        width: 100%;
        background-color: white;
        margin-top: 0.3rem !important;
    }

    .lineHeight {
        line-height: 25px;
    }

    .error {
        -webkit-border-radius: 4px !important;
        -moz-border-radius: 4px !important;
        border-radius: 4px !important;
        border: 1px solid red !important;
    }
}
