@import "../../../Variables";

.saveFilterLoader {
  position: absolute;
  right: 2rem;
  top: 1.4rem;
}

.saveFilterCounterLoader {
  position: absolute;
  left: 3rem;
  top: 0.8rem;
}

.rail {
  height: 5px;
}

.track {
  height: 5px;
}

.thumb {
  width: 15px;
  height: 15px;
}

.counters {
  margin-bottom: 1rem;
}