@import "../../Variables";

.labelClassName {
  font-weight: bold !important;
}

.buttonGroupClassName {
  display: flex !important;
  flex-wrap: wrap !important;
  row-gap: 1rem !important;
  column-gap: 0.5rem !important;
  margin-top: .75rem !important;
  color: #F6F5FE !important;
}

.buttonClassName {
  padding: 5px 10px !important;
  border-radius: 50px !important;
  text-transform: unset !important;
  background-color: #F6F5FE !important;
  color: $colorBlue !important;
  border: 1px solid #F6F5FE !important
}

//#F6F5FE
.checked {
  background-color: $colorBlue !important;
  color: #F6F5FE !important;
  border: 1px solid #F6F5FE !important
}

.menu {
  box-shadow: unset !important;
}