@import './../../../../Variables';

.checkboxHeight {
    padding: 0 !important;

    span {
        height: 20px !important;
    }
}

.autocompleteBackground {
    background-color: #f2f3f5 !important;
}

@media only screen and (max-width: 768px) {
}
