.noteContainer {
    position: relative;
    .noteAdded {
        position: absolute;
        right: 0;
    }
    .addNote {
        display: none;
    }
    &:hover {
        .addNote {
            display: block;
            position: absolute;
            right: 0;
        }
    }
}
