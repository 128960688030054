.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  min-height: 120px;
  margin-top: 0.3rem !important;

  .dropZoneInputContent {
    &:focus {
      outline: none !important;
    }

    text-align: center;

    p {
      font-size: 1rem;
      color: #3f50b5;
    }
  }
}

.mainUploadContainer {
  cursor: pointer;
  width: 100%;
  height: 100%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.dropZoneUploadContainer {
  height: 100%;
  width: 100%;

  &:focus {
    outline: none !important;
  }
}

.dropZoneContainerActive {
  border: 2px solid #F6343F !important;
}

.inputLabels {
  display: unset !important;
  font-size: 0.875rem !important;
  color: black !important;
}
