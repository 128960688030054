.inputLabels {
    display: unset !important;
    font-size: 0.875rem !important;
    color: black !important;
}

.editor {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    //padding: 1px;
    background: white;
    margin-top: 0.3rem;

    .wysiwygToolbar {
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.23);
        margin-bottom: 0;
    }

    .error {
        border: 1px solid red !important;
    }

    .wysiwygWrapper {
        background: white;

        .contentFocus {
            outline: 2px solid rgb(25, 118, 210);
        }

        .wysiwygContent {
            font-size: 1rem;
            padding: 0 10px;
            min-height: 390px;
            max-height: 355px;
            cursor: text;
        }
    }
}
