@import "../../../Variables";

.avatar {
  height: 50px !important;
  width: 50px !important;
  border-radius: unset !important;

  img {
    object-fit: contain;
  }
}