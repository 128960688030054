@import "../../../Variables";

.pageContainer {
  max-width: 1000px;
  margin: auto;
  padding: 0 16px 16px;

  .cardContainer {
    position: relative;
    background: #fff;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;

    .truncateTitle {
      font-size: 1rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 45px 0 0;
    }

    .avatar {
      height: 100px;
      width: 100px;
      border-radius: unset;
      //border: 1px solid $borderColor;
      img {
        object-fit: contain;
      }
    }
  }
}