@import "./../../../../Variables.scss";


.fixedFooter {
  z-index: 1;
  position: sticky;
  bottom: 0;
  left: 0;
  background: white;
  border-top: 2px solid rgba(51, 51, 51, 0.07);

  .btnContainer {
    button {
      text-transform: inherit;
    }

    .btnSubmit {
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      color: white;
      background-color: $colorBlue;
      float: right;

      &:hover {
        background-color: rgb(43, 128, 213);
      }
    }

    .listingBtnGroup {
      display: flex;
      justify-content: flex-end;

      button {
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        text-transform: inherit;
      }

      .btnDeleteSmall {
        display: none;
      }

      .btnDelete {
        color: white;
        background-color: red;

        &:hover {
          color: white;
          background-color: rgb(207 93 133);
        }
      }

      .btnOwnership {
        color: white;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .fixedFooter {
    bottom: calc(#{$mobileBottomNavHeight} - 2px);
  }
}

@media only screen and (max-width: 425px) {
  .btnContainer {
    .listingBtnGroup {
      .btnDeleteSmall {
        display: block;
      }

      .btnDeleteLarge {
        display: none;
      }
    }
  }
}