.inputContainer {
  .inputLabels {
    display: unset !important;
    font-size: 0.875rem !important;
    color: black !important;
  }

  input {
    padding: 12px;
  }

  .inputFields {
    width: 100%;
    background-color: white;
    margin-top: 0.3rem !important;
  }

  .dropdown {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    padding: 5px 12px !important;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    border-radius: 4px !important;

    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.87) !important;
    }

    &:before {
      border-bottom: none !important;
    }

    .dropdown {
      &:after {
        border: none !important;
      }
    }
  }
}
