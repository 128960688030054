$commonBlack: #292a2a;
$colorPrimary: rgb(51, 51, 51);
$colorSecondary: $commonBlack;
$colorBlue: $commonBlack; //#6200EE
$colorPurple: #5951ff;
$colorLightGray: #f2f3f5;
$colorGray: gray;
$colorLighterGray: $commonBlack;
$colorGreen: $commonBlack;
$colorDanger: $commonBlack;
$colorWhite: #fff;
$appHeaderHeight: 55px;
$mobileBottomNavHeight: 60px;
$inputDisabledBg: #ebebeb;
$colorDisabled: #a0a9a1;
$colorBlack: #001e3c;
$darkBlack: #000000;

$headerBg: #f6f6f6;
$leftMessageBg: #e9e9e9;
$chatInputBg: #f0f0f0;
$searchContainerHeight: 60px;
$chatFormHeight: 60px;
$borderColor: rgba(51, 51, 51, 0.07);
$listingsCardShadow: 0 2px 6px -2px rgb(0 106 194 / 20%);
$border: solid 1px #ebf0f6;
$homeColorBlue: $colorBlue;
$grayBg: #f9f9f9;
$lightBlue: #635cff;
$chipsBlue: #eff6ff;
$colorRed: #e65b65;

$circularStd: 'Circular Std Bold';
$circularStdMedium: 'Circular Std Medium';
$publicSans: 'Public Sans', sans-serif;
