@import '../../../Variables';

.appBarSpacerStepper {
    top: 110px;
    width: 100%;
    max-width: 1000px;
    margin: auto;
}
.wizardStepper {
    position: sticky;
    top: -20px;
    z-index: 111;
}
.appBarSpacer {
    position: relative;
    width: 100%;
    margin: 0 auto 0 auto;
}

@media only screen and (max-width: 425px) {
    .appBarSpacerStepper {
        top: 135px;
    }
}
