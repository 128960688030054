.upload {
    position: relative;
    width: fit-content;
    margin: auto;
    text-align: center;

    .avatarInput {
        display: none;
    }

    .avatar {
        border: 1px solid rgba(51, 51, 51, 0.07);
        border-radius: 50%;
        width: 70px;
        height: 70px;
        margin: auto;
        cursor: pointer;

        &:hover {
            opacity: 0.5;
        }
    }

    .avatarOpacity {
        opacity: 0.2;
    }

    .avatarProgress {
        position: absolute;
        top: 25%;
        left: 25%;
        z-index: 1;
        right: 25%;
        bottom: 25%;
        margin: auto;
    }
}

.btnUploadLabel {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    .btnUpload {
        pointer-events: none;
        text-transform: none;
    }
}

.w200 {
    width: 200px;
}

.top13 {
    top: -13px;
}

.uploadContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;

    .upload {
        margin-bottom: 30px;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .avatar {
            width: 100px; // Adjust as needed
            height: 100px; // Adjust as needed
            margin-bottom: 10px;
            border: 1px solid #ccc; // Optional
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); // Optional
        }

        .btnUpload {
            min-width: 120px; // Adjust as needed
        }

        .btnUploadLabel {
            margin-top: 10px;
        }
    }
}

@media (max-width: 768px) {
    .uploadContainer {
        flex-direction: column;
    }
}
