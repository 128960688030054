@import './../../../Variables';

.container {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 5px;
    .tagsContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 5px;
    }
}
.clearIcon {
    margin-right: 4px;
}

.popover {
    width: 400px;
    max-height: 400px;
    position: relative;
}
