@import "./../../../Variables";

.form {
  width: 100%;
}

.title {
  margin: auto;
  color: $colorBlue;
}

.colorGreen {
  color: $colorGreen !important;
}

.gridItem {
  margin: auto !important;
}

.gridContainer {
  flex-grow: 1;

  .titleContainer {
    margin: auto;
  }
}

.inputLabels {
  display: unset !important;
  font-size: 0.875rem !important;
  color: black !important;
}

.priceInputLayout {
  position: relative;

  .error {
    border: 1px solid red !important;
  }

  .inputPriceFields {
    width: 100%;
    margin-top: 0.3rem !important;

    input {
      padding: 12px 12px 12px 30px;
    }

    .disabled {
      background: $inputDisabledBg;
      color: $colorPrimary;
    }
  }

  .icon {
    z-index: 1;
    top: 1.2rem;
    left: 0.5rem;
    position: absolute;
    font-size: 14px !important;
  }
}


@media only screen and (max-width: 768px) {
  .form {
    padding-bottom: $mobileBottomNavHeight
  }
}