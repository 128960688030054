@import "../../Variables";

.linkListItem {
  color: $colorPrimary;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  .subMenu {
    margin: 0.5rem 1rem !important;
    background: #EAEEF3;
    border-radius: 4px;

    .subLinkListItem {
      color: $colorPrimary;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        color: $colorBlue;
      }

      .subMenuItem {
        cursor: pointer;
      }
    }
  }
}