@import '../../../Variables';
@mixin commonTag {
    border: $border;
    border-radius: 4px !important;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    min-width: 0 !important;
    padding: 4px 6px !important;
    font-size: 13px !important;
    line-height: 120% !important;
    color: rgb(68, 42, 30) !important;
    background-color: rgb(238, 224, 218);
    cursor: pointer;
    &:hover {
        background-color: $borderColor !important;
    }
}
.addTag {
    @include commonTag;
    &:hover {
        color: white !important;
    }
}
.tag {
    @include commonTag;
    span {
        padding-left: 0;
    }
    .icon {
        visibility: hidden;
    }
    &:hover {
        .icon {
            visibility: visible;
        }
    }
}

.container {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    max-width: 300px;
    .tagsContainer {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;
    }
}
.icon {
    svg {
        width: 0.8em;
        height: 0.8em;
    }
}
.popover {
    width: 300px;
    max-height: 400px;
    position: relative;
}
.loader {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 100;
}
.close {
    position: absolute;
    top: 10px;
    right: 1rem;
    z-index: 100;
}
.editPopover {
    width: 220px;
    position: relative;
}
.hoverItem {
    &:hover {
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.04);
    }
}
.deleteButton {
    color: #ff0000;
}
.headerTitle {
    font-size: 12px;
    padding: 6px 16px;
}

.tagsList {
    margin-top: 0;
    padding: 0;
    background: #fff;
    /*  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%),
        0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);*/
    border-radius: 4px;
    min-width: 120px;
    transition: opacity 267ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        transform 178ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transform-origin: 0 98px;
    list-style-type: none;
    width: 100%;
    li {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        font-size: 1rem;
        box-sizing: border-box;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-weight: 400;
        line-height: 1.5;
        white-space: nowrap;
        letter-spacing: 0.00938em;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        padding: 3px 16px;
        &:hover {
            text-decoration: none;
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}

.underLineClassName {
    margin-top: 0 !important;
    border-radius: 4px;
    div {
        &:before,
        &:after {
            border-bottom: 1px solid $borderColor;
        }
        input {
            //padding-left: 0;
        }
    }
}
.underLineClassNameWithoutPadding {
    div {
        input {
            //padding-left: 14px;
        }
    }
}
