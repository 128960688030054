.dataList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
}

.dataItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
    overflow: hidden;
}
