.profileUploadContainer {
  text-align: center;

  .profileUpload {
    position: relative;
    width: fit-content;
    margin: auto;

    .avatarInput {
      display: none
    }

    .avatar {
      border: 2px solid rgba(51, 51, 51, 0.07);
      border-radius: 50%;
      width: 150px;
      height: 150px;
      margin: auto;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }

    .avatarOpacity {
      opacity: 0.2;
    }

    .avatarProgress {
      position: absolute;
      top: 25%;
      left: 25%;
      z-index: 1;
      right: 25%;
      bottom: 25%;
      margin: auto;
    }
  }

  .btnUploadLabel {
    cursor: pointer;

    .btnUpload {
      pointer-events: none;
      text-transform: none;
    }
  }
}