@import '../../../Variables';

.inputContainer {
    .label {
        display: unset !important;
        font-size: 0.875rem;
        color: $colorPrimary !important;
    }

    .toggleButtonGroup {
        margin-top: 0.3rem;
    }

    .error {
        border: 1px solid red !important;
        margin-top: 0.3rem;
    }

    .disabled {
        background: $inputDisabledBg;
        color: $colorPrimary;
    }

    .toggleButton {
        text-transform: capitalize;
        color: $colorPrimary !important;
        padding: 7px 10px;
        border: 1px solid rgba(0, 0, 0, 0.23) !important;
        -webkit-border-radius: 4px !important;
        -moz-border-radius: 4px !important;
        border-radius: 4px;
        min-width: 50px;
    }

    .checked {
        background-color: $colorBlue !important;
        color: #fff !important;
    }
}
