@import '../../../Variables';

.avatar {
    height: 100px;
    width: 100px;
    border-radius: unset;
    //border: 1px solid $borderColor;
    img {
        object-fit: contain;
    }
}

.truncateTitle {
    font-size: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 45px 0 0;
}

.progressBar {
    width: 100%;
    border-radius: 50px;
    background-color: $colorLightGray;
    height: 30px;
}
