.avatar {
    border: 2px solid rgba(51, 51, 51, 0.07);
    border-radius: unset !important;
    width: 70px !important;
    height: 70px !important;
    margin: auto;
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }
}
